var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LoginForm", {
    attrs: {
      "data-cy": "login-form",
      "submit-title": _vm.$t(
        "@Authentication.authentication.components.LoginCredentialsForm.button"
      ),
      "is-submitting": _vm.isSubmitting,
      errors: _vm.scopeErrors
    },
    on: { submit: _vm.onSubmit },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [_c("IconLogoName", { staticClass: "header-logo" })]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("TextField", {
              attrs: {
                "data-cy": "login-email",
                "error-messages": _vm.scopeErrors.username,
                label: _vm.$t(
                  "@Authentication.authentication.components.LoginCredentialsForm.email"
                )
              },
              model: {
                value: _vm.email,
                callback: function($$v) {
                  _vm.email = $$v
                },
                expression: "email"
              }
            }),
            _vm._v(" "),
            _c("TextField", {
              attrs: {
                "data-cy": "login-pass",
                input: _vm.passwordInputType,
                "error-messages": _vm.scopeErrors.password,
                label: _vm.$t(
                  "@Authentication.authentication.components.LoginCredentialsForm.password"
                )
              },
              model: {
                value: _vm.password,
                callback: function($$v) {
                  _vm.password = $$v
                },
                expression: "password"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "login-help-area" },
              [
                _c("Toggler", {
                  attrs: {
                    "data-cy": "show-password",
                    label: _vm.$t(
                      "@Authentication.authentication.components.LoginCredentialsForm.toggle"
                    )
                  },
                  model: {
                    value: _vm.isPasswordVisible,
                    callback: function($$v) {
                      _vm.isPasswordVisible = $$v
                    },
                    expression: "isPasswordVisible"
                  }
                }),
                _vm._v(" "),
                _c("LinkButton", {
                  attrs: {
                    "data-cy": "forgot-password",
                    title: _vm.$t(
                      "@Authentication.authentication.components.LoginCredentialsForm.forgot"
                    )
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.redirectToRecovery.apply(null, arguments)
                    }
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }