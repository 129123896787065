var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LoginForm", {
    attrs: {
      "data-cy": "help-form",
      "submit-title": _vm.$t(
        "@Authentication.authentication.components.LoginHelpForm.redirectTo"
      )
    },
    on: { submit: _vm.onRedirect },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c("Fab", {
              nativeOn: {
                click: function($event) {
                  return _vm.onRedirect.apply(null, arguments)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function(ref) {
                    var color = ref.color
                    return [
                      _c("IconArrowPointer", { attrs: { "fill-color": color } })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("FormHeader", {
              attrs: {
                title: _vm.$t(
                  "@Authentication.authentication.components.LoginHelpForm.title"
                )
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("Paragraph", {
              attrs: {
                title: _vm.$t(
                  "@Authentication.authentication.components.LoginHelpForm.info"
                )
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }