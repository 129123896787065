var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Login",
    {
      on: { "redirect-to": _vm.onRedirectTo },
      scopedSlots: _vm._u(
        [
          _vm.loginFormComponents
            ? {
                key: "form",
                fn: function() {
                  return [
                    _c(_vm.loginFormComponents.formComponent, {
                      tag: "Component",
                      on: { "redirect-to": _vm.onRedirectTo }
                    })
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _vm.loginFormComponents
        ? _c("Infographic", {
            attrs: {
              "bg-url": _vm.loginFormComponents.bgUrl,
              "bg-position": _vm.loginFormComponents.bgPosition
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }