var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LoginForm", {
    attrs: {
      "submit-title": _vm.$t(
        "@Authentication.authentication.components.PasswordRecoveryCheckEmail.redirectTo"
      )
    },
    on: { submit: _vm.onRedirectToLogin },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c("Fab", {
              nativeOn: {
                click: function($event) {
                  return _vm.onBack.apply(null, arguments)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function(ref) {
                    var color = ref.color
                    return [
                      _c("IconArrowPointer", { attrs: { "fill-color": color } })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("FormHeader", {
              attrs: {
                title: _vm.$t(
                  "@Authentication.authentication.components.PasswordRecoveryCheckEmail.title"
                )
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("Paragraph", {
              attrs: {
                title: _vm.$t(
                  "@Authentication.authentication.components.PasswordRecoveryCheckEmail.info"
                )
              }
            }),
            _vm._v(" "),
            _c("Paragraph", {
              attrs: {
                title: _vm.$t(
                  "@Authentication.authentication.components.PasswordRecoveryCheckEmail.infoText"
                )
              },
              scopedSlots: _vm._u([
                {
                  key: "infoLink",
                  fn: function() {
                    return [
                      _c("a", {
                        staticClass: "paragraph__link",
                        attrs: { "aria-label": "Paragraph link" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "@Authentication.authentication.components.PasswordRecoveryCheckEmail.infoLink"
                            )
                          )
                        },
                        on: { click: _vm.onRedirectToHelp }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }